import styled, { x } from "@xstyled/styled-components";
import { useMemo, useState } from "react";

import { FilterLegend } from "./FilterLegend";
import { CHART_COLORS, UNKNOWN_COLOR } from "./graph-helpers";

import { pxToRem } from "@otta/design-tokens";
import { Spacing, Text, theme } from "@otta/design";

const Chart = styled.div`
  width: 100%;
  margin: auto;
  height: ${pxToRem(31)};
  display: flex;
  gap: xs;
  border-radius: ${pxToRem(50)};
  overflow: hidden;
`;

const ChartSection = styled.div<{ backgroundColor: string; width: number }>`
  width: ${({ width }) => `${width}%`};
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: sm;
  background-color: ${({ backgroundColor }) => backgroundColor};

  &[data-hovered="false"] {
    opacity: 0.3;
    transition: default;
  }
`;

export interface HorizontalChartData {
  percent: string;
  total: number;
  value: string;
}

export const HorizontalChart = ({ data }: { data: HorizontalChartData[] }) => {
  const [hoveredItem, setHoveredItem] = useState<string>();

  const sectionsWithData = useMemo(() => {
    const legend = new Set<string>();
    for (const section of data) {
      if (section.percent !== "0") {
        legend.add(section.value);
      }
    }

    return legend;
  }, [data]);

  return (
    <Spacing>
      <Chart data-testid="horizontal-chart">
        {data.map(
          (section: HorizontalChartData, index: number) =>
            parseInt(section.percent) !== 0 && (
              <ChartSection
                key={index}
                data-testid={`section-${section.value}`}
                backgroundColor={
                  section.value !== "Unknown"
                    ? CHART_COLORS[index]
                    : UNKNOWN_COLOR
                }
                width={
                  parseInt(section.percent) > 9
                    ? parseInt(section.percent) + 1
                    : 9
                }
                data-hovered={
                  !hoveredItem ? null : hoveredItem === section.value
                }
                onMouseEnter={() => setHoveredItem?.(section.value)}
                onMouseLeave={() => setHoveredItem?.("")}
              >
                <Text bold size={-1} align="right" style={{ width: "100%" }}>
                  {parseInt(section.percent)}%
                </Text>
              </ChartSection>
            )
        )}
      </Chart>
      <FilterLegend
        chartData={data}
        sectionsWithData={sectionsWithData}
        hoveredItem={hoveredItem}
        setHoveredItem={setHoveredItem}
      />
    </Spacing>
  );
};

export const UpsellHorizontalChart = () => (
  <Spacing>
    <Chart>
      <ChartSection backgroundColor={theme.colors["gray-100"]} width={35} />
      <ChartSection backgroundColor={theme.colors["gray-100"]} width={50} />
      <ChartSection backgroundColor={theme.colors["gray-100"]} width={7} />
      <ChartSection backgroundColor={theme.colors["gray-100"]} width={8} />
    </Chart>
    <x.div display="flex" gap="lg">
      <x.div w={73} h={12} backgroundColor="gray-100" />
      <x.div w={73} h={12} backgroundColor="gray-100" />
      <x.div w={73} h={12} backgroundColor="gray-100" />
    </x.div>
  </Spacing>
);
