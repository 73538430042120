import { Route, Routes } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { CompanyPipeline } from "./CompanyPipeline";
import { PreApproval } from "./PreApproval";
import { CompaniesIndex } from "./CompaniesIndex";

import { Text } from "@otta/design";
import { getAllowedCompanyWorkflowStatuses } from "@toolbox/utils/workflowStatus";
import { CurrentRoleType, useUser } from "@toolbox/utils/user";
import { CompanyWorkflowStatus } from "@toolbox/schema";
import { Link } from "@toolbox/components/Link";

const Links = styled.div`
  display: flex;
  align-items: center;
  padding: md 0;

  > * {
    margin-right: md;
  }
`;

const PageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: -md;
  margin-left: -md;
`;

export default function Companies(): React.ReactElement | null {
  const { user, role, permissions } = useUser();

  if (!user) {
    return null;
  }

  const statuses = getAllowedCompanyWorkflowStatuses(role, permissions);

  return (
    <PageWrapper>
      <Links data-testid="company-workflow-links">
        {statuses.has(CompanyWorkflowStatus.Archived) && (
          <Link to="archived">
            <Text bold>Archived</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.PushedToPro) && (
          <Link to="pushed-to-pro">
            <Text bold>Pushed to Pro</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.PreApproval) && (
          <Link to="pre-approval">
            <Text bold>Pre Approval</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.Approved) && (
          <Link to="approved">
            <Text bold>Approved</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.Queued) && (
          <Link to="queued">
            <Text bold>Queued</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.QaInternal) && (
          <Link to="qa-internal">
            <Text bold>QA</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.Changed) && (
          <Link to="changed">
            <Text bold>Changed</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.CompletedByUser) && (
          <Link to="completed-by-user">
            <Text bold>Completed by user</Text>
          </Link>
        )}
        {statuses.has(CompanyWorkflowStatus.Completed) && (
          <Link to="completed">
            <Text bold>Completed</Text>
          </Link>
        )}
      </Links>
      <PageWrapper>
        <Routes>
          {statuses.has(CompanyWorkflowStatus.Archived) && (
            <Route
              path="archived/*"
              element={
                <CompaniesIndex
                  role={role}
                  workflowStatus={CompanyWorkflowStatus.Archived}
                />
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.PushedToPro) && (
            <Route
              path="pushed-to-pro/*"
              element={
                <PreApproval
                  workflowStatus={CompanyWorkflowStatus.PushedToPro}
                />
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.PreApproval) && (
            <Route
              path="pre-approval/*"
              element={
                <PreApproval
                  workflowStatus={CompanyWorkflowStatus.PreApproval}
                />
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.Approved) && (
            <Route
              path="approved/*"
              element={
                <CompaniesIndex
                  role={role}
                  workflowStatus={CompanyWorkflowStatus.Approved}
                />
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.Queued) && (
            <Route
              path="queued/*"
              element={
                [
                  CurrentRoleType.InternalOperator,
                  CurrentRoleType.ExternalResearcher,
                ].includes(role) ? (
                  <CompanyPipeline
                    role={role}
                    workflowStatus={CompanyWorkflowStatus.Queued}
                  />
                ) : (
                  <CompaniesIndex
                    role={role}
                    workflowStatus={CompanyWorkflowStatus.Queued}
                  />
                )
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.QaInternal) && (
            <Route
              path="qa-internal/*"
              element={
                <CompaniesIndex
                  role={role}
                  workflowStatus={CompanyWorkflowStatus.QaInternal}
                />
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.Changed) && (
            <Route
              path="changed/*"
              element={
                <CompaniesIndex
                  role={role}
                  workflowStatus={CompanyWorkflowStatus.Changed}
                />
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.CompletedByUser) && (
            <Route
              path="completed-by-user/*"
              element={
                <CompaniesIndex
                  role={role}
                  workflowStatus={CompanyWorkflowStatus.CompletedByUser}
                />
              }
            />
          )}
          {statuses.has(CompanyWorkflowStatus.Completed) && (
            <Route
              path="completed/*"
              element={
                <CompaniesIndex
                  role={role}
                  workflowStatus={CompanyWorkflowStatus.Completed}
                />
              }
            />
          )}
        </Routes>
      </PageWrapper>
    </PageWrapper>
  );
}
