import { useLocation } from "react-router-dom";

import { CurrentRoleType } from "./user";

import {
  CompanyWorkflowStatus,
  JobWorkflowStatus,
  Permission,
} from "@toolbox/schema";

export function useNextWorkflowStatus(
  role: CurrentRoleType
): JobWorkflowStatus | null {
  const location = useLocation();

  switch (role) {
    case CurrentRoleType.Admin:
      return JobWorkflowStatus.Completed;

    case CurrentRoleType.InternalOperator:
      if (
        location.pathname.includes("changed") ||
        location.pathname.includes("qa-internal")
      ) {
        return JobWorkflowStatus.Completed;
      } else {
        return JobWorkflowStatus.QaInternal;
      }

    default:
      return null;
  }
}

export function usePreviousWorkflowStatus(
  role: CurrentRoleType
): JobWorkflowStatus | null {
  switch (role) {
    case CurrentRoleType.Admin:
      return JobWorkflowStatus.Disapproved;

    case CurrentRoleType.InternalOperator:
      return JobWorkflowStatus.Disapproved;

    default:
      return null;
  }
}

export function getAllowedJobWorkflowStatuses(
  role: CurrentRoleType,
  permissions: string[]
): Set<JobWorkflowStatus> {
  const roleStatuses = allowedRoleJobWorkflowStatuses[role] ?? [];
  const permStatuses =
    getAllowedPermissionsJobWorkflowStatuses(permissions) ?? [];
  return new Set(roleStatuses.concat(permStatuses));
}

export function getAllowedCompanyWorkflowStatuses(
  role: CurrentRoleType,
  permissions: string[]
): Set<CompanyWorkflowStatus> {
  const roleStatuses = allowedRoleCompanyWorkflowStatuses[role] ?? [];
  const permStatuses =
    getAllowedPermissionsCompanyWorkflowStatuses(permissions) ?? [];
  return new Set(roleStatuses.concat(permStatuses));
}

const getAllowedPermissionsJobWorkflowStatuses = (
  userPermissions: string[]
) => {
  let allAllowedWorkflows: JobWorkflowStatus[] = [];
  userPermissions.forEach(p => {
    const allowedWorkflows = allowedPermissionJobWorkflowStatuses[p] ?? [];
    allAllowedWorkflows = allAllowedWorkflows.concat(allowedWorkflows);
  });
  return allAllowedWorkflows;
};

export const getAllowedPermissionsCompanyWorkflowStatuses = (
  userPermissions: string[]
) => {
  let allAllowedWorkflows: CompanyWorkflowStatus[] = [];
  userPermissions.forEach(p => {
    const allowedWorkflows = allowedPermissionCompanyWorkflowStatuses[p] ?? [];
    allAllowedWorkflows = allAllowedWorkflows.concat(allowedWorkflows);
  });
  return allAllowedWorkflows;
};

const allowedRoleJobWorkflowStatuses: Partial<
  Record<CurrentRoleType, Array<JobWorkflowStatus>>
> = {
  [CurrentRoleType.Admin]: [
    JobWorkflowStatus.PreApproval,
    JobWorkflowStatus.QueuedExternal,
    JobWorkflowStatus.QaExternal,
    JobWorkflowStatus.QueuedInternal,
    JobWorkflowStatus.QaInternal,
    JobWorkflowStatus.Exception,
    JobWorkflowStatus.Disapproved,
    JobWorkflowStatus.CompletedByUser,
    JobWorkflowStatus.Completed,
    JobWorkflowStatus.Changed,
  ],
  [CurrentRoleType.InternalOperator]: [
    JobWorkflowStatus.PreApproval,
    JobWorkflowStatus.QueuedExternal,
    JobWorkflowStatus.Completed,
    JobWorkflowStatus.Changed,
  ],
};
const allowedPermissionJobWorkflowStatuses: Partial<
  Record<string, Array<JobWorkflowStatus>>
> = {
  [Permission.ManagePreApprovalJobs]: [JobWorkflowStatus.PreApproval],
  [Permission.ManageExceptionJobs]: [JobWorkflowStatus.Exception],
  [Permission.ManageQaInternalJobs]: [JobWorkflowStatus.QaInternal],
  [Permission.ManageQaExternalJobs]: [JobWorkflowStatus.QaExternal],
  [Permission.ManageCompletedByUserJobs]: [JobWorkflowStatus.CompletedByUser],
};

const allowedRoleCompanyWorkflowStatuses: Partial<
  Record<CurrentRoleType, Array<CompanyWorkflowStatus>>
> = {
  [CurrentRoleType.Admin]: [
    CompanyWorkflowStatus.Archived,
    CompanyWorkflowStatus.PushedToPro,
    CompanyWorkflowStatus.PreApproval,
    CompanyWorkflowStatus.Approved,
    CompanyWorkflowStatus.Queued,
    CompanyWorkflowStatus.QaInternal,
    CompanyWorkflowStatus.Changed,
    CompanyWorkflowStatus.CompletedByUser,
    CompanyWorkflowStatus.Completed,
  ],
  [CurrentRoleType.ExternalResearcher]: [CompanyWorkflowStatus.Queued],
  [CurrentRoleType.InternalOperator]: [
    CompanyWorkflowStatus.Queued,
    CompanyWorkflowStatus.CompletedByUser,
    CompanyWorkflowStatus.Completed,
    CompanyWorkflowStatus.Changed,
  ],
};

const allowedPermissionCompanyWorkflowStatuses: Partial<
  Record<string, Array<CompanyWorkflowStatus>>
> = {
  [Permission.ManagePreApprovalCompanies]: [CompanyWorkflowStatus.PreApproval],
  [Permission.ManageChangedCompanies]: [CompanyWorkflowStatus.Changed],
  [Permission.ManageQaInternalCompanies]: [CompanyWorkflowStatus.QaInternal],
  [Permission.ManageApprovedCompanies]: [CompanyWorkflowStatus.Approved],
  [Permission.ManageCompletedByUserCompanies]: [
    CompanyWorkflowStatus.CompletedByUser,
  ],
  [Permission.ManageCompletedCompanies]: [CompanyWorkflowStatus.Completed],
};
