import { rpxTransformers } from "@xstyled/styled-components";

export * from "./animations";

const space = {
  xxs: 2,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  xxl: 32,
  "3xl": 48,
  "4xl": 64,
  "5xl": 96,
  "6xl": 128,
  "7xl": 192,
};

export const theme = {
  space,
  inset: space,
  colors: {
    black: "#212121",
    white: "#FFFFFF",
    "beige-100": "#FBF9F7",
    "beige-200": "#F6F3EF",
    "beige-300": "#F0EBE5",
    "beige-400": "#D8D2CB",
    "blue-100": "#E0F5FF",
    "blue-200": "#9BDEF7",
    "blue-400": "#55C3E9",
    "blue-500": "#27A5D0",
    "blue-600": "#057AA3",
    "blue-800": "#025A79",
    "gray-50": "#FAFAFA",
    "gray-100": "#EEEEEE",
    "gray-200": "#D5D5D5",
    "gray-400": "#A5A5A5",
    "gray-600": "#717171",
    "green-100": "#EAFFD4",
    "green-200": "#BADE94",
    "green-400": "#9FC873",
    "green-500": "#83AD57",
    "green-600": "#5A8034",
    "green-800": "#2A4210",
    "orange-100": "#FFEBCE",
    "orange-200": "#FFD495",
    "orange-400": "#FF9F14",
    "orange-500": "#DB860A",
    "orange-600": "#A6670A",
    "orange-800": "#573607",
    "pink-100": "#FFE8F0",
    "pink-200": "#FFC5DB",
    "pink-300": "#FFA1C4",
    "pink-400": "#F696C8",
    "red-100": "#FBDEDC",
    "red-200": "#FCC7C3",
    "red-400": "#FF9490",
    "red-500": "#FF6165",
    "red-600": "#E1003A",
    "red-800": "#75001A",
    "yellow-100": "#FFF8D9",
    "yellow-200": "#FFE166",
    "yellow-500": "#FFCD00",
  },
  screens: {
    _: 0,
    mobile: 0,
    tablet: 768,
    desktop: 1024,
  },
  transitions: {
    default: "200ms ease-in-out",
  },
  icons: {
    sizes: {
      xs: 12,
      sm: 16,
      md: 24,
      lg: 32,
      xl: 48,
      xxl: 64,
    },
  },
  radii: {
    none: "0",
    sm: 2,
    md: 4,
    lg: 8,
    xl: 16,
    xxl: 24,
    full: "100%",
  },
  transformers: {
    // Transforms px values to rem
    ...rpxTransformers,
  },
} as const;

export type Theme = typeof theme;
