import styled from "@xstyled/styled-components";
import { useParams } from "react-router-dom";

import WorkflowButton from "./WorkflowButton";
import { useHasFullManageCompanyPermission } from "./useHasFullManageCompanyPermission";

import { CompanyWorkflowStatus } from "@toolbox/schema";

export const ButtonBar = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: md;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: lg;
`;

interface IWorkflowButtonsProps {
  workflowStatus: CompanyWorkflowStatus;
  onComplete?: () => void;
}

export function WorkflowButtons({
  workflowStatus,
}: IWorkflowButtonsProps): React.ReactElement | null {
  const { companyId } = useParams();
  if (!companyId) {
    throw new Error("No company id found");
  }

  switch (workflowStatus) {
    case CompanyWorkflowStatus.Archived:
      return (
        <ButtonBar data-testid="company-workflow-buttons">
          <WorkflowButton
            level="secondary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.PreApproval}
          >
            Move to pre approval
          </WorkflowButton>
          <WorkflowButton
            level="secondary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.Approved}
          >
            Approve
          </WorkflowButton>
          <WorkflowButton
            level="secondary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.Queued}
          >
            Queue
          </WorkflowButton>
        </ButtonBar>
      );

    case CompanyWorkflowStatus.Approved:
      return (
        <ButtonBar data-testid="company-workflow-buttons">
          <ArchiveButton
            workflowStatus={CompanyWorkflowStatus.Approved}
            companyId={companyId}
          />
          <WorkflowButton
            level="primary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.Queued}
          >
            Move to Queued
          </WorkflowButton>
        </ButtonBar>
      );

    case CompanyWorkflowStatus.Queued:
      return (
        <ButtonBar data-testid="company-workflow-buttons">
          <ArchiveButton
            workflowStatus={CompanyWorkflowStatus.Queued}
            companyId={companyId}
          />
          <WorkflowButton
            level="primary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.QaInternal}
          >
            Mark as done
          </WorkflowButton>
        </ButtonBar>
      );

    case CompanyWorkflowStatus.QaInternal:
      return (
        <ButtonBar data-testid="company-workflow-buttons">
          <ArchiveButton
            workflowStatus={CompanyWorkflowStatus.QaInternal}
            companyId={companyId}
          />
          <WorkflowButton
            level="secondary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.Queued}
          >
            Re-queue
          </WorkflowButton>
          <WorkflowButton
            level="primary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.Completed}
          >
            Mark as complete
          </WorkflowButton>
        </ButtonBar>
      );

    case CompanyWorkflowStatus.Completed:
      return (
        <ManagersOnly workflowStatus={CompanyWorkflowStatus.Completed}>
          <ButtonBar data-testid="company-workflow-buttons">
            <ArchiveButton
              workflowStatus={CompanyWorkflowStatus.Completed}
              companyId={companyId}
            />
            <WorkflowButton
              level="secondary"
              companyId={companyId}
              nextStatus={CompanyWorkflowStatus.QaInternal}
            >
              Move back to QA
            </WorkflowButton>
          </ButtonBar>
        </ManagersOnly>
      );

    case CompanyWorkflowStatus.CompletedByUser:
      return (
        <ManagersOnly workflowStatus={CompanyWorkflowStatus.CompletedByUser}>
          <ButtonBar data-testid="company-workflow-buttons">
            <ArchiveButton
              workflowStatus={CompanyWorkflowStatus.Changed}
              companyId={companyId}
            />
            <WorkflowButton
              level="primary"
              companyId={companyId}
              nextStatus={CompanyWorkflowStatus.CompletedByUser}
            >
              Mark as complete
            </WorkflowButton>
          </ButtonBar>
        </ManagersOnly>
      );

    case CompanyWorkflowStatus.Changed:
      return (
        <ButtonBar data-testid="company-workflow-buttons">
          <ArchiveButton
            workflowStatus={CompanyWorkflowStatus.Changed}
            companyId={companyId}
          />
          <WorkflowButton
            level="primary"
            companyId={companyId}
            nextStatus={CompanyWorkflowStatus.Completed}
          >
            Mark as completed again
          </WorkflowButton>
        </ButtonBar>
      );

    default:
      return null;
  }
}

function ManagersOnly({
  workflowStatus,
  children,
}: {
  workflowStatus: CompanyWorkflowStatus;
  children: React.ReactNode;
}): React.ReactElement | null {
  const show = useHasFullManageCompanyPermission(workflowStatus);
  return show ? <>{children}</> : null;
}

interface IArchiveButtonProps {
  workflowStatus: CompanyWorkflowStatus;
  companyId: string;
}

export function ArchiveButton({
  workflowStatus,
  companyId,
}: IArchiveButtonProps) {
  const canShow = useHasFullManageCompanyPermission(workflowStatus);
  return canShow ? (
    <WorkflowButton
      level="secondary"
      companyId={companyId}
      nextStatus={CompanyWorkflowStatus.Archived}
    >
      Archive
    </WorkflowButton>
  ) : null;
}
